export const loadingStates = {
    loaded: 'loaded',
    loading: 'loading',
    failed: 'failed',
};

export const textColorNames = {
    darkPrimary: 'darkPrimary',
    darkSecondary: 'darkSecondary',
    darkTertiary: 'darkTertiary',
    darkQuaternary: 'darkQuaternary',
    darkQuinary: 'darkQuinary',
    darkDisabled: 'darkDisabled',
    darkPlaceholder: 'darkPlaceholder',
    lightPrimary: 'lightPrimary',
    lightSecondary: 'lightSecondary',
    lightTertiary: 'lightTertiary',
    lightQuaternary: 'lightQuaternary',
    lightDisabled: 'lightDisabled',
    lightPlaceholder: 'lightPlaceholder',
    mediumDisabled: 'mediumDisabled',
    glassPrimary: 'glassPrimary',
    glassSecondary: 'glassSecondary',
    glassTertiary: 'glassTertiary',
    actionDark: 'actionDark',
    actionLight: 'actionLight',
    actionLightDisabled: 'actionLightDisabled',
    actionMediumDisabled: 'actionMediumDisabled',
    denimLight: 'denimLight',
    purple: 'purple',
    warnDark: 'warnDark',
    warnLight: 'warnLight',
};

export const darkTextColorNames = {
    darkPrimary: 'darkPrimary',
    darkSecondary: 'darkSecondary',
    darkTertiary: 'darkTertiary',
    darkQuaternary: 'darkQuaternary',
    actionDark: 'actionDark',
};

export const iconNames = {
    aim: 'aim',
    archive: 'archive',
    arrowRight: 'arrow-right',
    arrowUpLeft: 'arrow-up-left',
    board: 'board',
    bulb: 'bulb',
    calendar: 'calendar',
    canvas: 'canvas',
    check: 'check',
    chevronLeft: 'chevron-left',
    chevronRight: 'chevron-right',
    circleAlert: 'circle-alert',
    circlePlus: 'circle-plus',
    clock: 'clock',
    close: 'close',
    cloud: 'cloud',
    cloudDownload: 'cloud-download',
    cloudSolid: 'cloud-solid',
    cloudUpload: 'cloud-upload',
    collection: 'collection',
    context: 'context',
    contextHorizontal: 'context-horizontal',
    contextThin: 'context-thin',
    delta: 'delta',
    document: 'document',
    dollarSign: 'dollar-sign',
    doubleCloud: 'double-cloud',
    doubleLink: 'double-link',
    drag: 'drag',
    dragDrop: 'drag-drop',
    elevator: 'elevator',
    entryBoard: 'entry-board',
    filter: 'filter',
    folder: 'folder',
    folderPlus: 'folder-plus',
    folderSolid: 'folder-solid',
    folderTree: 'folder-tree',
    globe: 'globe',
    grid: 'grid',
    group: 'group',
    info: 'info',
    layout: 'layout',
    like: 'like',
    lineage: 'lineage',
    link: 'link',
    list: 'list',
    locked: 'locked',
    members: 'members',
    mindMap: 'mindmap',
    minus: 'minus',
    move: 'move',
    multiArrowRight: 'multi-arrow-right',
    multipleChoice: 'multiple-choice',
    number: 'number',
    orchestration: 'orchestration',
    paragraph: 'paragraph',
    percent: 'percent',
    perspectiveBoard: 'perspective-board',
    pin: 'pin',
    plus: 'plus',
    pointer: 'pointer',
    presenter: 'presenter',
    record: 'record',
    recordSolid: 'record-solid',
    reset: 'reset',
    settings: 'settings',
    search: 'search',
    sidebarContracted: 'sidebar-contracted',
    sidebarExpanded: 'sidebar-expanded',
    singleChoice: 'single-choice',
    sortDown: 'sort-down',
    sortUp: 'sort-up',
    spreadsheet: 'spreadsheet',
    team: 'team',
    textTool: 'text-tool',
    trash: 'trash',
    thinking: 'thinking',
    unlocked: 'unlocked',
    user: 'user',
    writer: 'writer',
    workbinBoard: 'workbin-board',
    workflow: 'workflow',
    workflowBoard: 'workflow-board',
    workspace: 'workspace',
    zoomIn: 'zoom-in',
    zoomOut: 'zoom-out',
};

export const iconButtonColors = {
    TRANSPARENT: 'transparent',
    PRIMARY: 'primary',
    ON_LIGHT: 'onLight',
    ON_DARK: 'onDark',
    ON_DARK_OUTLINE: 'onDarkOutline',
};

export const panelSurfaces = {
    SYSTEM: 'backgroundSystem',
    FROSTED_GLASS: 'backgroundFrostedGlass',
    GRAY: 'backgroundGray',
    GLASS: 'backgroundGlass',
};

export const colorSchemes = {
    LIGHT: 'light',
    DARK: 'dark',
    GLASS: 'glass',
};

export const buttonSizes = {
    COMPACT: 'compact',
    DEFAULT: 'default',
    LARGE: 'large',
};

export const buttonVariants = {
    PRIMARY: 'primary',
    SECONDARY: 'secondary',
    OUTLINE: 'outline',
};

export const emailValidationPattern =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
